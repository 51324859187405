import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About & Menu | Romano's Pizzeria
			</title>
			<meta name={"description"} content={"Indulge in the Art of Italian Cuisine"} />
			<meta property={"og:title"} content={"About & Menu | Romano's Pizzeria"} />
			<meta property={"og:description"} content={"Indulge in the Art of Italian Cuisine"} />
			<meta property={"og:image"} content={"https://barnislober.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://barnislober.com/img/4443330.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://barnislober.com/img/4443330.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://barnislober.com/img/4443330.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://barnislober.com/img/4443330.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://barnislober.com/img/4443330.png"} />
			<meta name={"msapplication-TileImage"} content={"https://barnislober.com/img/4443330.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
			About & Menu
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Our Menu
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					At Romano's Pizzeria, we are dedicated to bringing you the authentic flavors of Italy through our carefully curated menu. Each dish is crafted with love and passion, using only the finest ingredients to ensure an unforgettable dining experience. From our signature pizzas to our delectable appetizers and desserts, we have something to delight every palate.
					</Text>
				</Box>
				<Image src="https://barnislober.com/img/3.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://barnislober.com/img/4.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Our Culinary Philosophy
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					We believe that great food starts with great ingredients. That’s why we source our produce from local farmers and our cheeses and meats from trusted suppliers. Our chefs are masters of their craft, blending traditional Italian recipes with modern techniques to create dishes that are both classic and innovative. Whether you're a pizza purist or an adventurous foodie, you'll find something to love on our menu.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Try it
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Signature Pizzas
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Margherita: A classic favorite with San Marzano tomatoes, fresh mozzarella, and basil.
					<br/><br/>
Quattro Formaggi: A rich blend of mozzarella, gorgonzola, parmesan, and fontina cheeses.
					<br/><br/>
Pepperoni Delight: Generous portions of spicy pepperoni on a bed of mozzarella and tomato sauce.
					<br/><br/>
Veggie Supreme: A colorful mix of bell peppers, onions, mushrooms, and olives.
					<br/><br/>
Prosciutto e Rucola: Thinly sliced prosciutto topped with fresh arugula and shaved parmesan.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Appetizers
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Bruschetta: Toasted bread topped with a fresh tomato and basil mixture.
					<br/><br/>
Caprese Salad: Slices of fresh mozzarella, tomatoes, and basil drizzled with balsamic glaze.
					<br/><br/>
Garlic Knots: Soft, buttery knots of dough brushed with garlic and parsley.
					<br/><br/>
Arancini: Crispy rice balls filled with mozzarella and served with marinara sauce.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Salads
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Caesar Salad: Crisp romaine lettuce, croutons, and parmesan, tossed in a creamy Caesar dressing.
					<br/><br/>
Mediterranean Salad: Mixed greens, feta cheese, olives, cucumbers, and a lemon vinaigrette.
					<br/><br/>
Panzanella: Traditional Italian bread salad with tomatoes, cucumbers, and red onions.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Desserts
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Tiramisu: A classic Italian dessert with layers of coffee-soaked ladyfingers and mascarpone cream.
					<br/><br/>
Cannoli: Crispy pastry shells filled with sweet ricotta cream and chocolate chips.
					<br/><br/>
Panna Cotta: Creamy vanilla custard topped with fresh berries.

					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});